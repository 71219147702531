<script setup>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import ApiService from "../../common/service.api";
import SurveySection from "./SurveySection.vue";
import { IonIcon } from "@ionic/vue";
import { chevronDownOutline, chevronUpOutline } from "ionicons/icons";
import { useGeneralStore } from "@/stores/useGeneralStore.js";

const store = useStore();
const genStore = useGeneralStore();

const user = computed(() => store.getters["user/getUserData"]);
const users = genStore.userList;

const loading = ref({
  getUserSurveys: false,
});

const surveys = ref([]);
const completedSurveys = ref([]);
const pendingSurveys = ref([]);
const expiredSurveys = ref([]);

function toggleSurveySection(section) {
  if (section === "completedSurveys") {
    completedSurveys.value.visible = !completedSurveys.value.visible;
  } else if (section === "pendingSurveys") {
    pendingSurveys.value.visible = !pendingSurveys.value.visible;
  } else if (section === "expiredSurveys") {
    expiredSurveys.value.visible = !expiredSurveys.value.visible;
  }
}

async function getUserSurveys() {
  loading.value.getUserSurveys = true;
  surveys.value.splice(0);
  completedSurveys.value.splice(0);
  pendingSurveys.value.splice(0);
  expiredSurveys.value.splice(0);

  try {
    let result = await ApiService.get("Survey/GetMySurveys/" + user.value.id);

    const now = new Date();
    result.data.forEach((current) => {
      const survey = {
        id_survey: current.id_survey,
        t_nome: current.t_nome,
        inizio: current.inizio,
        fine: current.fine,
        surveys: [current],
      };

      if (current.completato) {
        // Survey is completed
        const existingSurvey = completedSurveys.value.find(
          (item) => item.id_survey === current.id_survey
        );
        if (existingSurvey) {
          existingSurvey.surveys.push(current);
        } else {
          completedSurveys.value.push(survey);
        }
      } else if (!current.completato && new Date(current.fine) >= now) {
        // Survey is pending
        const existingSurvey = pendingSurveys.value.find(
          (item) => item.id_survey === current.id_survey
        );
        if (existingSurvey) {
          existingSurvey.surveys.push(current);
        } else {
          pendingSurveys.value.push(survey);
        }
      } else if (!current.completato && new Date(current.fine) < now) {
        // Survey is expired
        const existingSurvey = expiredSurveys.value.find(
          (item) => item.id_survey === current.id_survey
        );
        if (existingSurvey) {
          existingSurvey.surveys.push(current);
        } else {
          expiredSurveys.value.push(survey);
        }
      }
    });

    // Set visibility states
    completedSurveys.value.visible = false;
    pendingSurveys.value.visible = true;
    expiredSurveys.value.visible = false;

    loading.value.getUserSurveys = false;
  } catch (error) {
    console.log(error);
    loading.value.getUserSurveys = false;
  }
}

onMounted(() => {
  getUserSurveys();
  if (users.length == 0) {
    genStore.getUsers();
  }
});
</script>

<template>
  <master-layout
    :showContentHeader="false"
    :showHeader="true"
    smallTitle="Le tue"
    bigTitle="Surveys"
  >
    <div
      v-if="loading.getUserSurveys"
      class="w-full flex justify-content-center align-items-center"
    >
      <ion-spinner name="crescent"></ion-spinner>
    </div>
    <div v-else>
      <div v-if="pendingSurveys.length">
        <div class="flex justify-content-between align-items-center">
          <h2>Pendenti</h2>
          <ion-icon
            :icon="
              pendingSurveys.visible ? chevronUpOutline : chevronDownOutline
            "
            @click="toggleSurveySection('pendingSurveys')"
            class="cursor-pointer"
          ></ion-icon>
        </div>
        <SurveySection
          v-if="pendingSurveys.visible"
          :surveys="pendingSurveys"
        />
      </div>
      <div v-if="completedSurveys.length">
        <div class="flex justify-content-between align-items-center">
          <h2>Completate</h2>
          <ion-icon
            :icon="
              completedSurveys.visible ? chevronUpOutline : chevronDownOutline
            "
            @click="toggleSurveySection('completedSurveys')"
            class="cursor-pointer"
          ></ion-icon>
        </div>
        <SurveySection
          v-if="completedSurveys.visible"
          :surveys="completedSurveys"
        />
      </div>

      <div v-if="expiredSurveys.length">
        <div class="flex justify-content-between align-items-center">
          <h2>Scadute</h2>
          <ion-icon
            :icon="
              expiredSurveys.visible ? chevronUpOutline : chevronDownOutline
            "
            @click="toggleSurveySection('expiredSurveys')"
            class="cursor-pointer"
          ></ion-icon>
        </div>
        <SurveySection
          v-if="expiredSurveys.visible"
          :surveys="expiredSurveys"
        />
      </div>
    </div>
  </master-layout>
</template>
