<script setup>
import { defineProps, onMounted, nextTick, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { IonIcon, IonCard, IonRow, IonCol } from "@ionic/vue";
import { checkmarkCircle, chevronDown, chevronUp } from "ionicons/icons";

import { useGeneralStore } from "../../stores/useGeneralStore";

import AvatarsSlider from "../../components/AvatarsSlider.vue";

const generalStore = useGeneralStore();
const users = computed(() => generalStore.userList);

const router = useRouter();

const showList1 = ref(false);
const showList2 = ref(false);
const showUsers = ref(false);

const props = defineProps({
  surveys: Array,
});

const toggleUsersVisibility = () => {
  showUsers.value = !showUsers.value;
};

const navigateToSurvey = (e) => {
  generalStore.setSelectedUser(e.id);
  generalStore.setCurrentSurvey(e.survey);
  router.push("/survey/" + e.id);
};

const swiperData = computed(() => {
  let list = [];

  props.surveys.forEach((survey) => {
    for (let s of survey.surveys) {
      list.push({
        url_image: users.value.find((u) => u.id == s.id_utente_valutato)
          .avatar_url,
        id: s.id,
        survey: s,
      });
    }
    // list.push({
    //   url_image: users.find((u) => u.id == survey.id_utente_valutato)
    //     .avatar_url,
    //   id: survey.id,
    // });
  });

  return list;
});

onMounted(async () => {
  if (users.value.length == 0) {
    await generalStore.getUsers();
  }

  showList1.value = true;
  setTimeout(async () => {
    await nextTick();
    showList2.value = true;
  }, 5);
});
</script>

<template>
  <transition-group name="block-slide-in">
    <template v-for="(survey, i) in surveys" :key="i">
      <ion-card
        v-if="showList1 && showList2"
        :style="{ '--i': i }"
        class="my-4 px-4 pb-4"
      >
        <div
          class="w-full flex flex-column justify-content-start align-items-start"
        >
          <div
            class="w-full flex justify-content-between align-items-center py-2"
          >
            <h4 class="font-bold">{{ survey.t_nome["it-IT"] }}</h4>
            <ion-icon
              v-if="survey.completato"
              :icon="checkmarkCircle"
              size="large"
            ></ion-icon>
          </div>
          <span>
            Inizio:
            <span class="font-bold">{{
              new Date(survey.inizio).toLocaleDateString()
            }}</span>
          </span>
          <span v-if="survey.fine">
            Fine:
            <span class="font-bold">{{
              new Date(survey.fine).toLocaleDateString()
            }}</span>
          </span>

          <!-- Pulsante per espandere/nascondere la riga utenti -->

          <div class="w-full flex justify-content-end align-items-center pt-4">
            <!-- Pulsante per navigare al sondaggio -->

            <button
              @click="toggleUsersVisibility"
              class="p-sm mr-2 text-button text-color-light flex align-items-center"
            >
              <small>Utenti</small>
              <ion-icon
                :icon="showUsers ? chevronUp : chevronDown"
                size="small"
                class="ml-1"
              ></ion-icon>
            </button>

            <!-- <ion-col>
              <button
                @click="navigateToSurvey(survey.id)"
                class="p-sm button-secondary"
              >
                Vai al sondaggio
              </button>
            </ion-col> -->
          </div>
        </div>

        <!-- Riga utenti visibile solo se showUsers è true -->
        <div v-if="showUsers">
          <AvatarsSlider
            :awardsList="swiperData"
            @clickedCurrentSlide="navigateToSurvey"
          />

          <!-- <ion-row class="w-full flex flex-wrap">
            <ion-col
              v-for="s in survey.surveys"
              :key="s.id"
              class="flex flex-column align-items-center justify-content-between ml-2 mb-4"
            >
              <div style="width: 55px; height: 55px">
                <img
                  class="w-full h-full"
                  :src="
                    users.find((u) => u.id == s.id_utente_valutato).avatar_url
                      ? users.find((u) => u.id == s.id_utente_valutato)
                          .avatar_url
                      : 'https://via.placeholder.com/50'
                  "
                  style="border-radius: 50%"
                />
              </div>
              <small style="text-align: center">
                {{
                  users.find((u) => u.id == s.id_utente_valutato).nome +
                  " " +
                  users.find((u) => u.id == s.id_utente_valutato).cognome
                }}
              </small>
            </ion-col>
          </ion-row> -->
        </div>
      </ion-card>
    </template>
  </transition-group>
</template>
